// import logo from "./logo.svg";
// import "./App.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./components/pages/home/Home";
// import Product from "./components/pages/product/Product";

// function App() {
//   return (
//     <Router>
//       {/* <Layout> */}
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/Product/:id" element={<Product />} />
//       </Routes>
//       {/* </Layout> */}
//     </Router>
//   );
// }

// export default App;


import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Home from "./components/pages/home/Home";
import Product from "./components/pages/product/Product";

const parseMetaTag = (metaTagString) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = metaTagString;

  const metaElement = tempDiv.querySelector('meta');
  if (metaElement) {
    const attributes = {};
    Array.from(metaElement.attributes).forEach(attr => {
      attributes[attr.name] = attr.value;
    });
    return attributes;
  }
  return null;
};

const App = () => {
  const { id } = useParams();
  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await axios.get("https://admin.indceramic.com/api/MetaApi");
        setMetaTags(response.data);
        console.log("metaTags", response.data);
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [id]);


  return (
    <div>
      <Helmet>
        {metaTags.map((metaTag, index) => {
          const parsedMeta = parseMetaTag(metaTag.metatag);
          return <meta key={index}  {...parsedMeta} />;
        })}
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Product/:id" element={<Product />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;