import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouseContent = () => {
  // Define state hooks
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define responsive settings for different screen sizes
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  // Fetch data from API
  useEffect(() => {
    fetch("https://admin.indceramic.com/api/SliderApi") // Replace with your actual API URL
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }
        return response.json(); // Parse response as JSON
      })
      .then((data) => {
        console.log("API Response:", data);
        setSliderData(data); // Set the fetched slider data
        setLoading(false);    // Update loading state to false
      })
      .catch((err) => {
        console.error("Error:", err);
        setError("An error occurred while fetching slider data.");
        setLoading(false);    // Update loading state to false if there's an error
      });
  }, []);

  // Loading state while fetching
  if (loading) {
    return <div>Loading...</div>;
  }

  // Error state if something went wrong
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Carousel
      responsive={responsive}
      itemClass="px-3"
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={4000}
    >
      {/* Loop through the sliderData to dynamically create carousel items */}
      {sliderData.map((item, index) => (
        <div key={index} className="row">
          <div
            className="col-lg-7 BackgroundLeftStyle1"
            style={{ padding: "80px 50px 50px 100px" }}
          >
            <p className="text HeaderHeadingStyle">
              {item.title}
              <br/>
              <span style={{ color: "#1E6FC0", textAlign: "center" }}>
              {item.name}
              {/* <p className="HeaderDescriptionStyle">{item.name}</p> */}
              </span>
            </p>
         
            <br />
            <p className="HeaderDescriptionStyle">{item.description}</p>
            <br />

            
            <a
              href="index.html#ContactUsSection"
              style={{
                color: "#fff",
                background:
                  "linear-gradient(to right, rgb(44,118,193), rgba(15,25,79))",
                padding: "10px 30px 10px 30px",
                textDecoration: "none",
                borderRadius: 5,
                boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.3)",
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Contact Now
            </a>
          </div>
          <div className="col-lg-5">
  {/* Dynamically render the image */}
  <img
    src={`https://admin.indceramic.com/Image/${item.image}`}
    alt={item.alt}
    style={{ height: "87vh" }}
  />
</div>

        </div>
      ))}
    </Carousel>
  );
};

export default CarouseContent;
